import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { photos } from './photo-data';

function PhotoSection() {
  const renderYoutubeVideo = () => {
    return (
      <iframe
        title="Pre-Wedding Jobins & Anju"
        width="100%"
        height="360px"
        src="https://www.youtube.com/embed/75w39OY7N-k"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <div id="fh5co-testimonial" className="fh5co-section-gray">
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">#Mark Your Calender</h2>
              <p className="sub-title">
                "Love is patient, love is kind. It does not envy, it does not boast, it is not proud. 
                It does not dishonor others, it is not self-seeking, it is not easily angered, it keeps 
                no record of wrongs. Love does not delight in evil but rejoices with the truth. It always 
                protects, always trusts, always hopes, always perseveres." <br />
                (1 Corinthians 13:4-8).
              </p>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-10 col-md-offset-1">{renderYoutubeVideo()}</div>
          </div> */}
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <ImageGallery items={photos} showBullets={false} lazyload={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoSection;
